
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        








































































































































































































































































































































































































































































































































































































































































$bp: m;

/* stylelint-disable selector-max-combinators */
/* stylelint-disable selector-max-compound-selectors */
.stores-section {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 80px, xxl: 120px));

  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 80px, xxl: 120px));

  // prettier-ignore
  @include fluid(gap,(xxs: 50px, xxl: 80px));

  display: flex;
  flex-direction: column;
}

.stores__map {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @include mq($bp) {
    gap: 0;
  }
}

.stores__map-header {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2.5rem inside-col(2);
  background-color: $tango;

  @include mq($bp) {
    gap: 2rem;
    padding: 4rem;
  }

  @include mq($until: $bp) {
    margin: 0 inside-col(-2);
  }
}

.stores__search {
  @include mq($bp) {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid $black;
  }
}

.stores__search__input {
  // prettier-ignore
  @include fluid(font-size,(xxs: 18px, xxl: 24px));

  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  color: $white;
  font-weight: 400;
  background-color: $tango;
  border: 0;
  border-bottom: 1px solid $black;

  &::placeholder {
    color: $white;
  }

  @include mq($bp) {
    flex: 1;
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
}

.stores__search__cta {
  display: flex;
  gap: 1rem;

  ::v-deep .btn {
    width: 50%;
    padding: 0;
  }

  @include mq($bp) {
    ::v-deep .btn {
      width: auto;
    }
  }
}

.stores__search__cta__submit,
.stores__search__cta__filters {
  ::v-deep {
    .btn__label {
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: 1.5rem 2.5rem;
    }

    .icon {
      flex-shrink: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0;
      fill: $white;
    }
  }

  @include mq($bp) {
    ::v-deep {
      .icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.stores__search__cta__submit {
  &::before {
    background-color: $tango-dark;
  }
}

.stores__search__cta__filters {
  @include mq($bp) {
    display: none;
  }
}

.stores__filter {
  display: none;

  &.is-open {
    display: block;
  }

  @include mq($bp) {
    display: block;
  }
}

.stores__filter__main {
  &.mobile {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: $tango-dark;
  }

  @include mq($bp) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    flex-wrap: wrap;
    gap: 2rem 5rem;

    &.mobile {
      display: none;
    }
  }
}

.stores__filter__secondary {
  display: none;

  @include mq($until: $bp) {
    &.mobile {
      display: block;
      overflow: hidden;
      height: 0;
    }
  }
}

.stores__filter__checkbox {
  ::v-deep .label {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }

  &.is-checked {
    ::v-deep .label {
      color: $white;
    }
  }
}

.stores__map__switcher {
  display: flex;
  padding: 0;
  gap: 1rem;

  @include mq($bp) {
    display: none;
  }
}

.stores__map__switcher__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 1rem;
  padding: 1.5rem 2.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  background-color: $c-gray-lightest;
  border: 0;
  transition: background-color 0.15s;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover,
  &.is-active {
    color: $white;
    background-color: $cornflower;
  }
}

.stores__map-outer {
  position: relative;
  overflow: auto;
  height: 60rem;

  @include mq($bp) {
    display: flex;
    // grid-template-columns: minmax(27rem, 30%) 1fr;
    height: 50rem;
    gap: 1rem;
  }
}

.stores__map__list,
.stores__map__canvas {
  background-color: $white;

  @include mq($until: $bp) {
    @include get-all-space;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
  }
}

.stores__map__list {
  @include scrollbars($background-color: $c-gray-lighter);

  overflow: auto;
  margin-top: 1rem;
  padding-right: 1rem;

  @include mq($until: $bp) {
    .list & {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include mq($bp) {
    width: min(40%, 30rem);
  }
}

.stores__map__list__item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2.5rem;

  &.is-active {
    background-color: rgba($c-gray-lightest, 0.3);
  }

  &:not(:last-child) {
    border-bottom: 1px solid $c-gray-lighter;
  }

  @include mq($bp) {
    padding: 2.5rem 2rem 2.5rem 4rem;
  }
}

.stores__map__list__item__title {
  // prettier-ignore
  @include fluid(font-size,(xxs: 15px, xxl: 18px));

  // prettier-ignore
  @include fluid(line-height,(xxs: 20px, xxl: 24px));

  font-weight: 400;
}

.stores__map__list__item__link,
.stores__map__list__item__address {
  // prettier-ignore
  @include fluid(font-size,(xxs: 12px, xxl: 15px));

  // prettier-ignore
  @include fluid(line-height,(xxs: 16px, xxl: 20px));
}

.stores__map__list__item__link {
  color: $tango;
  font-weight: 400;
}

.stores__map__canvas {
  flex: 1;

  ::v-deep {
    .cluster span {
      font-weight: 700;
    }

    .cluster-0 span {
      font-size: 14px;
    }

    .cluster-1 span {
      font-size: 18px;
    }

    .cluster-2 span {
      font-size: 22px;
    }
  }

  @include mq($until: $bp) {
    .map & {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
