
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



























.split-lines {
  text-align: center;
}

.headline {
  margin-bottom: 2rem;

  @include mq(m) {
    margin-bottom: 4rem;
  }
}

.line-container {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 30px,
      xl: 60px,
    )
  );

  @include fluid(
    margin-bottom,
    (
      mob: 30px,
      xl: 50px,
    )
  );

  overflow: hidden;
  max-width: 77rem;
  margin: 0 auto;
  font-weight: 700;
  line-height: 1.3;

  .is-hidden & {
    visibility: hidden;
  }

  .line {
    transform: translateY(100%);
  }
}
