
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        















































































































































































































































































































































.business {
  overflow: hidden;
}

.business__store-cta {
  @include mq(m) {
    display: flex;
    justify-content: flex-end;

    ::v-deep {
      .content {
        flex: 0;
        flex-basis: inside-col(7);
      }
    }
  }
}

.business__order {
  overflow: auto;
  width: 100vw;
  max-width: 142rem;
  height: 100%;
  margin-left: auto;
  padding: 8rem 2rem 5rem;
  background: $c-white;

  @include mq(s) {
    padding: 5rem 5rem 10rem;
  }
}

.business__order-container {
  max-width: 98rem;
  margin: 0 auto;
}

.business__order__count-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}

.business__order__step {
  margin-top: 3rem;
  margin-bottom: 0;

  @include mq(m) {
    margin-top: 6rem;
  }
}

.business__order__title {
  margin-bottom: 4.5rem;
}

.business__order__count {
  display: flex;
  align-items: center;
  border: 1px solid $alto;

  span {
    @include brown;

    padding: 0 2rem;
    font-size: 1.5rem;
    vertical-align: middle;
  }

  button {
    padding: 0.5rem 1.5rem 1rem;
    color: $tango;
    font-size: 3.5rem;
    line-height: 1;
    border: 1px solid $alto;

    &:disabled {
      opacity: 0.5;
    }

    &:first-child {
      border-width: 0 1px 0 0;
    }

    &:last-child {
      border-width: 0 0 0 1px;
    }
  }
}

.business__order__email {
  margin-left: auto;
}

.business__order__tva {
  padding: 2rem;
  background: rgba($alto, 0.2);
  border: 0;

  @include mq($until: m) {
    flex-basis: 100%;
  }
}
