
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        













































































































































































































.title {
  @include brown;

  margin-bottom: 3rem;
}

.checkbox {
  border: 0;

  ::v-deep .label {
    font-weight: 500;
  }
}
