
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




















































































































































































.header {
  margin-top: 10rem;
  margin-bottom: 5rem;
  border-bottom: 1px $alto solid;

  @include mq(m) {
    margin-bottom: 8rem;
  }
}

.form {
  padding-top: 0;
  background: $c-white;

  ::v-deep {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.form-selection {
  width: 100%;
}

.step-title {
  margin-bottom: 4rem;

  @include mq(m) {
    margin-bottom: 6rem;
  }
}

.next-btn {
  margin-left: auto;
}
