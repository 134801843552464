
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        


































































































































































$bp: xs;

@import '~flickity/dist/flickity.css';

.slider,
[class*='slider--'] {
}

.slider__items {
  ::v-deep {
    .flickity-viewport {
      overflow: visible;
      width: 100%;
    }
  }

  @include mq($bp) {
    display: flex;
    padding-bottom: 2rem;
  }
}

.slider__nav {
  display: flex;
  justify-content: space-between;
}

.slider__nav__button {
  position: relative;
  z-index: 10;
  width: 6rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  svg {
    width: 100%;
  }
}

.slider-item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  background: $white;
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba($black, 0.1);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .is-ready & {
    height: 100%;
  }

  .is-dragging & {
    pointer-events: none;
  }

  @include mq($bp) {
    width: 39rem;
    padding: 3rem;

    &:not(:last-child) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
}

.slider-item__picture {
  position: relative;
  flex: 1;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1/1;

  img {
    @include image-fit;

    transition: transform 0.15s;
  }

  .slider-item:hover & {
    img {
      transform: scale(1.1);
    }
  }
}

.slider-item__footer {
  @include brown;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

.slider-item__footer__address {
  display: flex;
  gap: 1rem;
}

.slider-item__footer__link {
  padding-left: 3.5rem;
  color: $tango;
}

.slider-item__picture,
.slider-item__footer {
  @include mq($until: $bp) {
    display: none;
  }
}
