
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        













.message {
  display: inline-block;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  border: 1px solid $alto;
  border-radius: 3px;
}

.success-message {
  color: darken($yellow-green, 50);
  background: rgba($yellow-green, 0.5);
  border-color: darken($yellow-green, 10);
}

.error-message {
  color: darken($monza, 10);
  background: rgba($monza, 0.5);
  border-color: darken($monza, 10);
}
