
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        






































.select {
  position: relative;
  margin: 0 0 3rem;
  border-bottom: 0.1rem solid $alto;

  &.has-error {
    border-bottom: 0.1rem solid $monza;
  }

  @include mq(m) {
    margin: 0 0 6rem;
  }
}

.real-select {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  display: block;
  color: $tango;

  .has-error & {
    color: $monza;
  }
}

.placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: $gray !important;
  }
}

.options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  height: auto;
  padding: 1rem 2rem;
  background: $c-white;
  border: 1px solid $c-black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;

  .is-open & {
    opacity: 1;
    visibility: visible;
  }
}

.option {
  display: flex;
  padding: 0.5rem 0 0.3rem;
  color: $gray;
  font-size: 1.8rem;

  &:hover {
    color: $c-black;
  }
}

.icon {
  width: 1.2rem;
  height: 0.8rem;
  transition: transform 0.2s;

  .is-open & {
    transform: scale(-1);
  }
}
