
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        


















































































.chocolaterie {
  overflow: hidden;
}

.chocolaterie__overlay {
  justify-content: center;
  ::v-deep {
    .close {
      display: none;
    }
  }
}

.chocolaterie__player {
  width: 99vw;
  max-width: 120rem;
  margin: 0 auto;
}

.chocolaterie__video {
  position: relative;
  display: block;
  overflow: hidden;
  max-height: 57rem;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (82 / 241) * 100%;
  }

  img {
    @include image-fit;
  }
}

.chocolaterie__video__btn {
  position: absolute;
  z-index: 25;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: $white;
  border: 0;

  p {
    font-size: 15px;
  }

  svg {
    margin-left: 8px;
    fill: $tango;
  }
}
