
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





.step-counter {
  position: relative;
  margin-bottom: 2rem;
  color: $tango;
  font-size: 2.4rem;
  font-weight: 300;

  &::before {
    content: '';
    position: absolute;
    top: 65%;
    left: 0;
    width: 3rem;
    height: 1px;
    background: $c-black;
    transform: rotate(135deg);
  }

  &::after {
    content: '2';
    display: block;
    margin-left: 2rem;
    color: $c-black;
    font-size: 1.3rem;
  }

  @include mq(m) {
    font-size: 3.6rem;

    &::after {
      font-size: 1.5rem;
    }
  }
}
