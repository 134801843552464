
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



















.checkbox {
  position: relative;
  display: flex;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    display: block;
  }
}

.label {
  @include brown;

  padding: 0 0 0 2.5rem;
  color: $c-black;
  font-size: 1.8rem;
  line-height: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1.8rem;
    height: 1.8rem;
    background: $white;
    border: 0.2rem solid $black;
    border-radius: 3px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 1.8rem;
    height: 1.8rem;
    background: url('../../assets/images/icons/check.svg') no-repeat center;
  }
}
