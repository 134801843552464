
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





















.floating-items {
  position: relative;
}

.item {
  position: absolute;
  pointer-events: none;
  filter: none;
  min-width: 5rem;
  will-change: transform;

  @include mq($until: s) {
    display: none;
  }
}
