
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




























































.double-card,
[class*='double-card--'] {
  text-align: center;
}

.container {
  max-width: 1680px;
  margin: 0 auto;

  &::before {
    @include fluid(
      height,
      (
        mob: 70px,
        xl: 110px,
      )
    );

    content: '';
    display: block;
    float: right;
    width: col(7.5);
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  [class*='double-card--'][class*='--highlighted'] & {
    margin-top: 10rem;
  }
}

.title {
  @include fluid(
    font-size,
    (
      mob: 36px,
      xl: 84px,
    )
  );

  line-height: 1.1;
}

.subtitle {
  @include brown;
  @include fluid(
    font-size,
    (
      mob: 18px,
      xl: 24px,
    )
  );

  margin-bottom: 5rem;
  font-weight: 300;
}

.card {
  // prettier-ignore
  @include fluid(margin-top, (mob: 20px, xl: 45px));
  // prettier-ignore
  @include fluid(margin-bottom, (mob: 20px, xl: 45px));

  position: relative;
  display: block;
  float: left;
  width: inside-col(8, 20);
  margin-left: inside-col(1, 20);
  text-align: left;

  &:nth-child(even) {
    float: right;
    margin-right: inside-col(1, 20);
    margin-left: 0;
  }

  [class*='double-card--'][class*='--single'] & {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  [class*='double-card--'][class*='--highlighted'] & {
    &:first-child {
      width: inside-col(10, 20);
      margin-left: inside-col(-1, 20);
    }

    &:nth-child(2) {
      width: inside-col(10, 20);
      margin-right: inside-col(-1, 20);
    }
  }

  [class*='double-card--'][class*='--big'] & {
    width: inside-col(9, 20);
    margin-left: 0;

    &:nth-child(even) {
      float: right;
      margin-right: 0;
      margin-left: 0;
    }
  }

  @include mq(s) {
    width: inside-col(6, 20);
    margin-left: inside-col(3, 20);

    &:nth-child(even) {
      margin-right: inside-col(3, 20);
    }
  }

  @include mq(xxl) {
    [class*='double-card--'][class*='--big'] & {
      width: inside-col(8, 20);
      margin-left: inside-col(1, 20);

      &:nth-child(even) {
        float: right;
        margin-right: inside-col(1, 20);
        margin-left: 0;
      }
    }
  }

  @include mq(xxxl) {
    width: inside-col(6, 18);
    margin-left: inside-col(2, 18);

    &:nth-child(even) {
      margin-right: inside-col(2, 18);
    }

    [class*='double-card--'][class*='--highlighted'] & {
      &:first-child {
        width: inside-col(10);
        margin-left: inside-col(-2);
      }

      &:nth-child(2) {
        width: inside-col(10);
        margin-right: inside-col(-2);
      }
    }

    [class*='double-card--'][class*='--big'] & {
      width: inside-col(8, 18);
      margin-left: 0;

      &:nth-child(even) {
        float: right;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.bar,
.tablet,
.fragments {
  position: absolute;
  z-index: 1;
  top: 0;
  transition: transform 0.2s;
}

.bar {
  left: 45%;
  height: 65%;
  transform: translateY(-30%) rotate(-10deg);

  .card:hover & {
    transform: translateY(-30%) rotate(-10deg) scale(1.05);
  }
}

.tablet {
  left: 5%;
  height: 55%;
  transform: translateY(-20%);

  .card:hover & {
    transform: translateY(-20%) scale(1.05);
  }
}

.fragments {
  @include fluid(
    width,
    (
      mob: 70px,
      xl: 100px,
    )
  );

  transform: translateY(-150%);
}

.picture {
  @include fluid(
    margin-bottom,
    (
      mob: 10px,
      xl: 20px,
    )
  );

  position: relative;
  overflow: hidden;
  padding-top: 100%;

  img {
    @include image-fit;

    transition: transform 0.2s;
  }

  .card:hover & {
    img {
      transform: scale(1.05);
    }
  }
}

.link-title {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 17px,
      xl: 30px,
    )
  );

  display: flex;
  align-items: center;
  font-weight: 700;

  svg {
    margin-top: 3px;
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
  }

  .card:hover & {
    svg {
      opacity: 1;
      transform: translateX(20px);
    }
  }
}
