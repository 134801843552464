
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




















.label {
  display: block;
}

input {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  border: 0;

  &::placeholder {
    color: $alto;
  }
}
