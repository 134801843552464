
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        













.overlay-panel {
  @include get-all-space;

  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 100vh;
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }
}

.bg {
  @include get-all-space;

  background: rgba($tango, 0.6);
}

.content {
  position: relative;
  z-index: 1;
  width: auto;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s;

  .is-visible & {
    transform: translateX(0);
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0;
  background: $c-black;
  border: 0;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-white;
  }
}
