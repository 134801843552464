
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        






































































.about-us {
  overflow: hidden;
}

.separator {
  height: 1px;
  background: $alto;
  border: 0;
}

.engagement-title {
  @include fluid(
    font-size,
    (
      mob: 60px,
      xl: 240px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      mob: 40px,
      xl: 170px,
    )
  );

  line-height: 0.8;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $c-black;
}
